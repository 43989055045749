import React from 'react';
import {IDesignTemplate} from '@gfxco/contracts';
import IconTooltip from '../../Icons/IconTooltip';
import './AddToProductListingItem.scss';

type AddToProductListingItemProps = {
  integrationConnected: boolean;
  design: IDesignTemplate;
  handleAddToProductListing: (design: IDesignTemplate) => void;
};

const AddToProductListingItem: React.FC<AddToProductListingItemProps> = ({
  integrationConnected,
  design,
  handleAddToProductListing,
}) => {
  const handleOnClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    handleAddToProductListing(design);
  };

  return (
    <div
      id="publish-button"
      className={integrationConnected ? 'enable-publish-tooltip' : ''}
    >
      <IconTooltip
        box
        fillColor={{default: '#4573bb', disabled: '#d6d6d6'}}
        disabled={!integrationConnected}
        name="publishIcon"
        onClick={handleOnClick}
        tooltipText={
          !integrationConnected
            ? 'Need to integrate with Shopify to enable this option.'
            : 'Publish'
        }
      />
    </div>
  );
};

export default AddToProductListingItem;
