import {
  IUpdateShopParams,
  IUpdateShopRequest,
  GetShopsResponse,
  UserManagementDataResponse,
  IInstallationConfigs,
  CreateShopRequest,
} from '@gfxco/contracts';
import {handleError, loadClient} from './api';

export const updateShop = async (
  params: IUpdateShopRequest & IUpdateShopParams,
) => {
  const {
    name,
    shop_image_url: shopImageUrl,
    hex_color: hexColor,
    description,
  } = params;
  const body: IUpdateShopRequest = {name, shop_image_url: shopImageUrl};

  if (hexColor) {
    body.hex_color = hexColor;
  }

  if (description) {
    body.description = description;
  }

  try {
    const client = await loadClient();
    const bodyParams = {
      ...body,
    };
    const response = await client.put<GetShopsResponse[]>(
      `/shops/${params.shopId}`,
      bodyParams,
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getShopUsers = async () => {
  try {
    const client = await loadClient();
    const response = await client.get<UserManagementDataResponse>(
      `/shops/users`,
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getGeneralShopConfigs = async (params: IUpdateShopParams) => {
  try {
    const client = await loadClient();
    const response = await client.get<IInstallationConfigs>(
      `/shops/${params.shopId}/installation-configs`,
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const postShopifyExtension = async (params: IUpdateShopParams) => {
  try {
    const client = await loadClient();
    const response = await client.post(`/shops/${params.shopId}/install-theme`);

    return response.data;
  } catch (error: any) {
    console.error(error);
    if (error.response) {
      return error.response.data;
    }
    return handleError(error);
  }
};

export const createShop = async (params: CreateShopRequest) => {
  try {
    const client = await loadClient();
    const response = await client.post(`/shops`, params);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};
