import React from 'react';
import {Link} from 'react-router-dom';
import Icon from '../Icons/Icon';
import CustomModal from '../CustomModal';

import './IntegrationWarningModal.scss';

interface RequiredProps {
  show: boolean;
  onClose: () => void;
}

interface OptionalProps {}

type IntegrationWarningModalProps = RequiredProps & OptionalProps;

const IntegrationWarningModal: React.FC<IntegrationWarningModalProps> = ({
  show,
  onClose,
}) => {
  return (
    <CustomModal
      modalTile=""
      variant="styled"
      sizeType="middle-page"
      show={show}
      onCloseModal={onClose}
    >
      <div id="IntegrationWarningModal">
        <Icon name="printerNotConnected" />
        <div className="integration-text-warning">
          Looks like{' '}
          <b>you haven&apos;t configured any fulfillment services yet.</b>
          To accept this order, please configure your printer or webhook service
          in your fulfillment center.
        </div>
        <Link className="integration-link" to="/printers" target="_blank">
          Go to fulfillment center
        </Link>
      </div>
    </CustomModal>
  );
};

export default IntegrationWarningModal;
