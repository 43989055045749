import React from 'react';
import {Link} from 'react-router-dom';

import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {
  getModerationOrdersAsync,
  resetModerationOrders,
  selectTotalModerationOrders,
  selectModerationOrdersFetchLoading,
  selectModerationOrders,
  selectModerationOrdersSelected,
  updateSelectAllOrders,
  selectAllSelected,
  updateSelectOrder,
  updateBulkItemsAsync,
  selectBulkUpdateFetchStatus,
  resetBulkUpdateFetchStatus,
} from '../../features/moderation/loadModerationOrders';
import {selectShop} from '../../features/shops/selectedShop';
import {
  selectFulfillmentServiceFetchStatus,
  selectFulfillmentServiceStatus,
  getFulfillmentServicesAsync,
} from '../../features/fulfillmentService/loadFulfillmentService';
import ModerationFilters from './ModerationFilters';
import ModerationContent from './ModerationContent';
import './ModerationByShopPage.scss';
import Icon from '../../components/Icons/Icon';
import ModerationStatuses from './ModerationStatuses';
import SortLabel from '../../components/SortLabel';
import {GFXToastLaunch} from '../../components/ToastMessage/ToastMessage';
import AcceptBulkItems from './AcceptBulkItems';

import {
  ELoadingStates,
  EModerationItemStatus,
  IGetModerationFiltersRequest,
  IModerationOrdersRequest,
} from '@gfxco/contracts';
import {
  getModerationFiltersAsync,
  selectModerationFilters,
} from '../../features/moderation/loadFilters';
import {
  getDesignsCountAsync,
  selectDesignsCount,
  selectDesignsCountFetchLoading,
  resetModerationOrdersCountFetchStatus,
} from '../../features/moderation/loadDesignsCount';

import Banner from '../../components/Banner';

export const ModerationByShopPage: React.FC = () => {
  const shopSelected = useAppSelector(selectShop);

  const fulfillmentServiceFetchStatus = useAppSelector((state) =>
    selectFulfillmentServiceFetchStatus(state),
  );

  const fulfillmentServices = useAppSelector((state) =>
    selectFulfillmentServiceStatus(state),
  );

  const limit = 9;

  if (!shopSelected) {
    return <></>;
  }

  const shopId = React.useMemo(() => shopSelected.id, [shopSelected]);
  const defaultFilters: IModerationOrdersRequest = {
    shopId,
    orderBy: 'desc',
    pageSize: limit,
  };

  const [filters, setFilters] =
    React.useState<IModerationOrdersRequest>(defaultFilters);

  const [filtersParams, setFiltersParams] =
    React.useState<IGetModerationFiltersRequest>({
      shopId,
    });

  const designsCountFetchStatus = useAppSelector((state) =>
    selectDesignsCountFetchLoading(state),
  );

  const designsCount = useAppSelector((state) => selectDesignsCount(state));

  const orders = useAppSelector((state) => selectModerationOrders(state));

  const ordersSelected = useAppSelector((state) =>
    selectModerationOrdersSelected(state),
  );

  const totalOrders = useAppSelector((state) =>
    selectTotalModerationOrders(state),
  );

  const ordersFetchStatus = useAppSelector((state) =>
    selectModerationOrdersFetchLoading(state),
  );

  const moderationFilters = useAppSelector((state) =>
    selectModerationFilters(state),
  );

  const allSelected = useAppSelector((state) => selectAllSelected(state));

  const bulkUpdateFetchStatus = useAppSelector((state) =>
    selectBulkUpdateFetchStatus(state),
  );

  const existsFullfilment = Object.values(fulfillmentServices).some((v) => !!v);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(updateSelectAllOrders({selected: false}));
    if (ordersFetchStatus === ELoadingStates.LOADED) {
      handleOnReset();
    }
  }, [shopId]);

  React.useEffect(() => {
    if (ordersFetchStatus === ELoadingStates.IDLE) {
      if (filters.shopId !== shopId) {
        dispatch(getModerationOrdersAsync({...defaultFilters, shopId}));
      } else {
        dispatch(getModerationOrdersAsync({...filters, shopId}));
      }
    }
  }, [shopId, ordersFetchStatus, dispatch]);

  React.useEffect(() => {
    if (designsCountFetchStatus === ELoadingStates.IDLE) {
      dispatch(getDesignsCountAsync(shopId));
    }
  }, [shopId, designsCountFetchStatus, dispatch]);

  React.useEffect(() => {
    if (filtersParams.shopId !== shopId) {
      dispatch(getModerationFiltersAsync({shopId}));
    } else {
      dispatch(getModerationFiltersAsync({...filtersParams, shopId}));
    }
  }, [shopId, filtersParams, dispatch]);

  React.useEffect(() => {
    if (shopId) {
      dispatch(getFulfillmentServicesAsync(shopId));
    }
  }, [shopId]);

  React.useEffect(() => {
    if (ordersFetchStatus === 'loaded') {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [ordersFetchStatus]);

  React.useEffect(() => {
    if (bulkUpdateFetchStatus === ELoadingStates.LOADED) {
      GFXToastLaunch(
        <span className="bulk-toast bulk-toast--success">
          All selected items have been <b>approved and sent to the printer</b>
        </span>,
        5000,
        {
          showIcon: true,
          alertType: 'success',
          showAt: 'top',
          parentContainerId: 'ModerationPage',
          right: '3.5rem',
        },
      );
      handleOnReset();
      dispatch(resetBulkUpdateFetchStatus());
      if (allSelected) {
        dispatch(updateSelectAllOrders({selected: false}));
      }
    }
    if (bulkUpdateFetchStatus === ELoadingStates.FAILED) {
      GFXToastLaunch(
        <span className="bulk-toast">
          There was an error approving the orders. Please try again later.
          Contact support if the problem persists.
        </span>,
        5000,
        {
          showIcon: true,
          alertType: 'danger',
          showAt: 'top',
          parentContainerId: 'ModerationPage',
          right: '3.5rem',
        },
      );
      dispatch(resetBulkUpdateFetchStatus());
    }
  }, [bulkUpdateFetchStatus]);

  const handleFiltersChange = (filters: IModerationOrdersRequest) => {
    setFiltersParams((prev) => ({
      ...prev,
      ...{
        status: filters.status,
        review: filters.review,
        templateId: filters.templateId,
        productColor: filters.productColor,
        productType: filters.productType,
      },
    }));
    setFilters({...filters, cursor: undefined});
    dispatch(resetModerationOrders());
    dispatch(updateSelectAllOrders({selected: false}));
  };

  const handleSortChange = (selectedOption: any) => {
    dispatch(resetModerationOrders());

    const orderBy = selectedOption.value === 'newest' ? 'desc' : 'asc';

    setFilters({...filters, orderBy, cursor: undefined});
  };

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    if (
      scrollTop + clientHeight < scrollHeight ||
      ordersFetchStatus === 'loading' ||
      (totalOrders && totalOrders <= orders.length)
    ) {
      return;
    }

    const cursor = orders[orders.length - 1]?.itemId;
    dispatch(getModerationOrdersAsync({...filters, cursor}));
  };

  const handleOnReset = () => {
    dispatch(resetModerationOrders());
    dispatch(resetModerationOrdersCountFetchStatus());
    if (filters.shopId !== shopId) {
      setFilters({...defaultFilters, shopId});
      setFiltersParams({shopId});
    } else {
      dispatch(getModerationOrdersAsync({...filters, shopId}));
    }
  };

  const handleClickAcceptItems = () => {
    dispatch(
      updateBulkItemsAsync(
        allSelected
          ? {
              shopId: filters.shopId,
              productColor: filters.productColor,
              productType: filters.productType,
              templateId: filters.templateId,
              date: filters.date,
              orderId: filters.orderId,
              review: filters.review,
              status: filters.status,
            }
          : {
              itemIds: ordersSelected.map((order) => order.itemId),
              status: EModerationItemStatus.ACCEPTED,
            },
      ),
    );
  };

  const handleSelectItem = (itemId: number, selected: boolean) => {
    dispatch(updateSelectOrder({itemId, selected}));
  };

  const handleSelectAll = (selectAll: boolean) => {
    dispatch(updateSelectAllOrders({selected: selectAll}));
  };

  return (
    <div id="ModerationPage">
      <Banner
        show={
          fulfillmentServiceFetchStatus === ELoadingStates.LOADED &&
          !existsFullfilment
        }
      >
        <b> Just one more step:</b> To accept this order, please configure your
        printer or webhook service in your{' '}
        <Link to="/printers" target="_blank">
          Fulfillment Center
        </Link>
      </Banner>
      <ModerationFilters
        filtersOptions={moderationFilters}
        filters={filters}
        totalOrders={totalOrders}
        onFiltersChange={(filters) => {
          handleFiltersChange(filters);
        }}
        allSelected={allSelected}
        onSelectAllChange={(selectAll) => {
          handleSelectAll(selectAll);
        }}
      />

      <div className="section">
        <ModerationStatuses
          statuses={designsCount}
          filters={filters}
          fetchStatus={designsCountFetchStatus}
          onFiltersChange={(filters) => {
            handleFiltersChange(filters);
          }}
        />
        <div className="section inner-section">
          <AcceptBulkItems
            ordersSelected={ordersSelected.length}
            allSelected={allSelected}
            totalOrders={totalOrders}
            loading={bulkUpdateFetchStatus === ELoadingStates.LOADING}
            handleClickAcceptItems={handleClickAcceptItems}
          />
          <SortLabel
            handleSortChange={handleSortChange}
            sortValue={
              filters.orderBy && filters.orderBy === 'asc' ? 'oldest' : 'newest'
            }
            className="select-sort-moderation-orders"
          />
        </div>
      </div>

      {orders.length === 0 && ordersFetchStatus === ELoadingStates.LOADED && (
        <div className="empty-orders">
          <Icon name="cartWithAlert"></Icon>
          <p>{"Looks like you haven't received any orders yet"}</p>
        </div>
      )}

      <ModerationContent
        orders={orders}
        ordersFetchStatus={ordersFetchStatus}
        onReset={handleOnReset}
        printerStatus={fulfillmentServices}
        printerFetchStatus={fulfillmentServiceFetchStatus}
        allSelected={allSelected}
        onSelectItem={handleSelectItem}
      />
    </div>
  );
};
