import React from 'react';
import {ELoadingStates, IUpdateShopParams, constants} from '@gfxco/contracts';
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import './HomePage.scss';
import {selectShop, selectShopConfigs} from '../../features/shops/selectedShop';
import {
  getGeneralShopConfigsAsync,
  selectGeneralConfigsShops,
  selectGeneralConfigsFetchLoading,
  loadInstallationConfigs,
} from '../../features/shops/loadInstallationConfigs';
import {selectUserPermissions} from '../../features/user';

import Alert from 'react-bootstrap/Alert';
import {ExclamationTriangle} from 'react-bootstrap-icons';
import HomeImage from '../../assets/gfx_home_page.png';

import HomeSteps from './HomeSteps';

import ConfirmationModal from './ConfirmationModal';

import FloatingYoutubeThumbnail from './FloatingYoutubeVideo/FloatingYoutubeThumbnail';
import Icon from '../../components/Icons/Icon';
import {postShopifyExtension} from '../../api';
import {GFXToastLaunch} from '../../components/ToastMessage/ToastMessage';
const {USER_PERMISSIONS} = constants;

const HomePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const shopSelected = useAppSelector(selectShop);
  const userPermissions = useAppSelector(selectUserPermissions);
  const isOwner =
    (userPermissions && userPermissions.includes(USER_PERMISSIONS.ALL)) ||
    false;
  const shopConfigs = useAppSelector(selectShopConfigs);
  const [openExtensionVideo, setOpenExtensionVideo] = React.useState(false);
  const shopId = shopSelected?.id;
  const configs = useAppSelector((state) => selectGeneralConfigsShops(state));
  const fetchStatus = useAppSelector((state) =>
    selectGeneralConfigsFetchLoading(state),
  );

  React.useEffect(() => {
    getConfigs();
  }, [shopId]);

  const onClosePopup = () => {
    setOpenExtensionVideo(false);
  };

  const getConfigs = async () => {
    if (!shopId) return;
    const params: IUpdateShopParams = {
      shopId,
    };

    dispatch(getGeneralShopConfigsAsync(params));
  };

  const handleRedirectToTheme = () => {
    if (!shopId || !configs) return;

    let domain =
      shopSelected.shopify_domain || shopSelected.public_shopify_domain;
    domain = shopSelected.public_shopify_domain?.replace('.myshopify.com', '');
    const GFXProductJsonName = `gfx-product`;
    const templateName = configs.template?.name;

    if (!templateName) {
      GFXToastLaunch(
        'You need to publish your first design to check our theme.',
        5000,
        {
          parentContainerId: 'HomePage',
          showAt: 'bottom',
          alertType: 'info',
          showIcon: true,
        },
      );
      return;
    }

    const productToRedirect = `/products/${templateName}?view=${GFXProductJsonName}`;
    const queryParam = `?previewPath=${encodeURI(productToRedirect)}`;
    const themeUrl = `https://admin.shopify.com/store/${domain}/themes/${configs.themeId}/editor${queryParam}`;

    const element = document.createElement('a');
    element.setAttribute('href', themeUrl);
    element.setAttribute('target', '__blank');
    element.click();
    document.removeChild(element);
  };

  const onInstallExtension = async () => {
    if (!shopId || !configs) return;

    if (configs?.extensionInstalled) {
      return handleRedirectToTheme();
    }

    try {
      const response = await postShopifyExtension({
        shopId,
      });

      if (response.error) {
        GFXToastLaunch(`Error installing extension: ${response.error}`, 5000, {
          parentContainerId: 'HomePage',
          showAt: 'bottom',
          alertType: 'danger',
          showIcon: true,
        });
        return;
      }

      GFXToastLaunch('Shopify Extension Installed', 5000, {
        parentContainerId: 'HomePage',
        showAt: 'bottom',
        alertType: 'success',
        showIcon: true,
      });

      if (response.themeId) {
        dispatch(
          loadInstallationConfigs.actions.updateField({
            field: 'themeId',
            value: response.themeId,
          }),
        );
        dispatch(
          loadInstallationConfigs.actions.updateField({
            field: 'extensionInstalled',
            value: true,
          }),
        );
      }
    } catch (error: any) {
      console.log('Error installing Shopify Extension', error);
      GFXToastLaunch(`Error installing extension: ${error.message}`, 5000, {
        parentContainerId: 'HomePage',
        showAt: 'bottom',
        alertType: 'danger',
        showIcon: true,
      });
    }
  };

  const showBanner =
    shopConfigs &&
    (['', undefined, null].includes(shopConfigs.stripeSubscriptionToken) ||
      shopConfigs.stripeSubscriptionStatus !== 'active') &&
    (['', undefined, null].includes(shopConfigs.shopifySubscriptionId) ||
      !shopConfigs.shopifySubscriptionStatus);

  return (
    <div id="HomePage">
      {showBanner && (
        <div className="payment-banner">
          <ExclamationTriangle />
          <span className="banner-text">
            Oops! We couldn&apos;t find an active subscription, Check your
          </span>
          <a>billing settings on shopify</a>
          <span className="banner-text"> to update your payment method.</span>
        </div>
      )}
      <div className="home-header">
        <div className="home-header-text">
          <span className="home-header-subtitle">Welcome to gfx!</span>
          <span className="home-header-title">{`Let's get creative!`}</span>
          <span className="home-header-bold">
            Allow your fans to personalize products aligned with your brand
            standards.
          </span>
        </div>
      </div>
      <div className="home-body">
        <img className="home-image" src={HomeImage} />
        <div className="home-body-text">
          <div className="title-icon-span">
            <Icon
              name="checkIcon"
              fillColor={{
                default: '#4573BB',
              }}
            />
            <span>Store setup checklist:</span>
          </div>
          <HomeSteps
            onInstallExtension={async () => onInstallExtension()}
            configs={configs}
            isLoading={fetchStatus === ELoadingStates.LOADING}
            isOwner={isOwner}
          />
          {fetchStatus === ELoadingStates.FAILED && isOwner && (
            <Alert variant="danger">
              There was an error loading your permissions
            </Alert>
          )}
        </div>
      </div>
      <FloatingYoutubeThumbnail
        openVideo={openExtensionVideo}
        onClosePopup={onClosePopup}
      />
      <ConfirmationModal />
    </div>
  );
};

export default HomePage;
