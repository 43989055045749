import React from 'react';
import {useGFXInstance} from '@best-apps/gfx-editor';
import {IBuilderSlots, IColor} from '@gfxco/contracts';
import SlotsAlias from './SlotsAlias';
import Loading from '../Loading/Loading';
import GFXButton from '../Button/Button';
import TextIco from '../../assets/icons/add-text-slot-icon.svg';
import ImageIco from '../../assets/icons/add-image-slot-icon.svg';
import BackgroundIco from '../../assets/icons/add-background-slot-icon.svg';
import {ReactComponent as ProductViewIcon} from '../../assets/icons/product-view-icon.svg';
import {ReactComponent as ProductViewActiveIcon} from '../../assets/icons/product-view-active-icon.svg';
import {ReactComponent as InformationIcon} from '../../assets/icons/information-icon.svg';
import {GFXInstance} from '@best-apps/gfx-editor/lib/package/dist/types/GFXInstance';
import toPascalCase from '../../libs/toPascalCase';

import './TemplateBuilderButton.scss';
import classNames from 'classnames';
import ScalingAlert from './ScalingAlert/ScalingAlert';

type TemplateBuilderButtonsProps = {
  productName: string;
  onSlotsChange: () => void;
  displayColors?: IColor[];
  isSaving: boolean;
};

type SectionType = 'front' | 'back';

export const extractSlots = (gfx: GFXInstance, currentSide: SectionType) => {
  if (!gfx) return [];
  const currentObjects = gfx?.state.design.sections[currentSide]
    ?.objects as IBuilderSlots[];
  const filtered =
    currentObjects
      ?.filter((item) => !item.isVisibleArea && item.type !== 'path')
      .reverse() || [];
  return filtered;
};

const TemplateBuilderButtons: React.FC<TemplateBuilderButtonsProps> = (
  props,
) => {
  const gfx = useGFXInstance();
  const [slots, setCurrentSlots] = React.useState<IBuilderSlots[]>([]);
  const [currentSide, setCurrentSide] = React.useState<SectionType>('front');
  const [sections, setSections] = React.useState<SectionType[]>([]);
  const activeObject = gfx?.state.activeObject;
  const isGroupMode = gfx?.state.groupMode;
  const [newSlot, setNewSlot] = React.useState<{height: number; width: number}>(
    {
      height: 100,
      width: 100,
    },
  );
  const listRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const backgroundSlot = slots.find((item) => item.isBackground);

  React.useEffect(() => {
    if (!gfx) return;

    setSections(
      Object.keys(gfx.state.design.sections).filter((sectionName) => {
        const section =
          gfx.state.design.sections[sectionName as 'front' | 'back'];
        return section !== null && section !== undefined;
      }) as SectionType[],
    );
  }, [gfx, gfx?.state.design.sections]);

  React.useEffect(() => {
    if (!gfx) return;
    const filtered = extractSlots(gfx, currentSide);
    setCurrentSlots(filtered || []);
    setTimeout(sendScrollToTop, 100);
  }, [
    currentSide,
    gfx?.state.design.sections.front?.objects,
    gfx?.state.design.sections.back?.objects,
  ]);

  React.useEffect(() => {
    if (currentSide !== gfx?.state.activeSection) {
      gfx?.actions.flipCanvas(currentSide);
    }
  }, [currentSide]);

  const handleBackgroundSlotCreation = async () => {
    if (props.isSaving) return;
    resetAddSlot();
    await gfx?.actions.addSlot({slotType: 'background'});
    props.onSlotsChange();
  };

  const handleImageSlotCreation = async () => {
    if (props.isSaving) return;
    await createImageSlot(newSlot.width, newSlot.height);
    props.onSlotsChange();
  };

  const createImageSlot = async (width: number, height: number) => {
    const imageSlots = slots.filter((item) => item.type === 'rect');

    const colorsUsedOnImageSlots = imageSlots.reduce((prev: string[], item) => {
      if (item.fill !== undefined) {
        prev.push(item.fill);
      }
      return prev;
    }, []);

    await gfx?.actions.addSlot({
      height,
      width,
      colorsUsed: colorsUsedOnImageSlots,
    });
    resetAddSlot();
    props.onSlotsChange();
  };

  const resetAddSlot = () => {
    setNewSlot({
      width: 100,
      height: 100,
    });
  };

  const handleTextSlotCreation = async () => {
    if (props.isSaving) return;
    resetAddSlot();
    const fonts = gfx?.state.initialData.v2Template.fonts || [];
    const defaultFont =
      fonts.find((font) => font.fontFamily === 'Helvetica Neue') ?? fonts[0];

    if (defaultFont) {
      await gfx?.actions.addText('Add your text', {
        fontFamily: defaultFont.fontFamily,
        fontId: defaultFont.id,
      });
    } else {
      await gfx?.actions.addText('Add your text');
    }

    props.onSlotsChange();
  };

  const setSlotAlias = async (alias: string, uuid: string) => {
    if (props.isSaving) return;
    await gfx?.actions.setSlotAlias({
      alias,
      uuid,
    });
    resetAddSlot();
    props.onSlotsChange();
  };

  const removeSlot = async (uuid: string) => {
    if (props.isSaving) return;
    await gfx?.actions.removeSlot({uuid});
    props.onSlotsChange();
  };

  const sendScrollToTop = () => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
  };

  const SlotComponents = () => {
    return (
      <>
        {slots.length > 0 && (
          <>
            <div className="slots-created-list__header">
              <span>
                Slot Type <InformationIcon />
              </span>
              <span>
                Slot Name <InformationIcon />
              </span>
            </div>
            {slots
              .filter((item) => !item.isBackground)
              .map((item, index) => {
                const handleChangeOrder = async (newPosition: number) => {
                  if (props.isSaving) return;
                  const fullSlots =
                    gfx?.state.design.sections[currentSide]?.objects || [];
                  const realNewPosition = fullSlots.length - 2 - newPosition;
                  await gfx?.actions.moveElementTo(item.uuid, realNewPosition);
                  props.onSlotsChange();
                };

                return (
                  <SlotsAlias
                    isActive={activeObject?.uuid === item.uuid}
                    index={index}
                    totalSlots={
                      backgroundSlot ? slots.length - 1 : slots.length
                    }
                    setSlotAlias={setSlotAlias}
                    slot={item}
                    key={item.uuid}
                    onClick={async () => {
                      await gfx?.actions.setObjectActive(item.uuid);
                    }}
                    onRemoveSlot={removeSlot}
                    onChangeOrder={handleChangeOrder}
                  />
                );
              })}
          </>
        )}
      </>
    );
  };

  const BackgroundItem = () => {
    if (!backgroundSlot) {
      return <></>;
    }

    return (
      <SlotsAlias
        isActive={activeObject?.uuid === backgroundSlot.uuid}
        index={0}
        onClick={async () => {
          await gfx?.actions.setObjectActive(backgroundSlot.uuid);
        }}
        slot={backgroundSlot}
        setSlotAlias={setSlotAlias}
        key={backgroundSlot.uuid}
        onRemoveSlot={removeSlot}
      />
    );
  };

  const SlotsRendered = React.memo(SlotComponents);

  if (!gfx) {
    return <Loading />;
  }

  const cssClasses = classNames('template-builder-buttons', {
    'template-builder-buttons--blurred': isGroupMode,
  });

  return (
    <div className="configure-product-design">
      <div className="design-name">
        <h2>{props.productName}</h2>
      </div>
      <div className="configure-product-design__content">
        <div className={cssClasses}>
          <div className="template-builder-buttons__header">
            <h3>
              Your Slot Template: <InformationIcon />
            </h3>
            <div className="section-group views">
              Choose your view:
              <div className="section-group">
                {sections.map((section) => (
                  <GFXButton
                    variant="light"
                    onClick={() => setCurrentSide(section)}
                    key={section}
                    active={currentSide === section}
                  >
                    {currentSide === section ? (
                      <ProductViewActiveIcon />
                    ) : (
                      <ProductViewIcon />
                    )}
                    {toPascalCase(section)} view
                  </GFXButton>
                ))}
              </div>
            </div>
          </div>
          <div className="button-controls">
            <button onClick={handleImageSlotCreation}>
              <img src={ImageIco} alt="" />+ Add Image slot
            </button>
            <button onClick={handleTextSlotCreation}>
              <img src={TextIco} alt="" />+ Add Text slot
            </button>
            <button onClick={handleBackgroundSlotCreation}>
              <img src={BackgroundIco} alt="" />+ Add Background
            </button>
          </div>
          <div className="slots-created-list" ref={listRef}>
            <SlotsRendered />
            <BackgroundItem />
          </div>
        </div>
      </div>
      {isGroupMode && <ScalingAlert />}
    </div>
  );
};

export default TemplateBuilderButtons;
