import React from 'react';
import {
  IColor,
  GetTemplateByIdResponse,
  IBuilderSlots,
  ELoadingStates,
  WalkthroughStep,
} from '@gfxco/contracts';
import {
  useParams,
  createSearchParams,
  useNavigate,
  unstable_useBlocker as useBlocker,
  unstable_BlockerFunction as BlockerFunction,
} from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import SlotControlsTabs from './SlotControlsTabs';
import SlotControlsHeader from './SlotControlsHeader';
import SlotControlsMain from './SlotControlsMain';
import ShopifyPublishModal from '../../components/ShopifyPublishModal';
import CloseConfirmationModal from '../../components/CloseConfirmationModal';
import Loading from '../../components/Loading';

import {
  getDesignTemplate,
  updateTemplate,
  updateWalkthrough,
  updatePublishStatus,
} from '../../api';

import './SlotTemplatePage.scss';
import NotFound from '../../components/NotFound/NotFound';
import {useAppSelector} from '../../app/hooks';
import {selectShop} from '../../features/shops/selectedShop';

const updateUrlSearchParam = (urlToUpdate?: string) => {
  if (!urlToUpdate) {
    return undefined;
  }

  const url = new URL(urlToUpdate);
  const params = url.searchParams;
  params.set('v', Date.now().toString());
  url.search = params.toString();

  return url.toString();
};

const SlotTemplatePage: React.FC = () => {
  const [selectedProof, setSelectedProof] = React.useState<'front' | 'back'>(
    'front',
  );
  // State use to detect changes on the template
  const [originalCanvaTemplate, setOriginalCanvaTemplate] =
    React.useState<GetTemplateByIdResponse>();

  const [canvaTemplate, setCanvaTemplate] =
    React.useState<GetTemplateByIdResponse>();

  const [fetchStatus, setFetchStatus] = React.useState<ELoadingStates>(
    ELoadingStates.IDLE,
  );
  const [isSaving, setIsSaving] = React.useState(false);
  const [isPublishing, setIsPublishing] = React.useState(false);
  const [canvaTemplateChanged, setCanvaTemplateChanged] =
    React.useState<boolean>(false);
  const [showSavedMessage, setShowSavedMessage] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const [previousPage, setPreviousPage] = React.useState('/designs');
  const [showShopifyPublishModal, setShowShopifyPublishModal] =
    React.useState(false);
  const [originalShopId, setOriginalShopId] = React.useState<number | null>(
    null,
  );

  const selectedShop = useAppSelector(selectShop);

  const params = useParams();
  const navigate = useNavigate();
  const {id: canvaTemplateId} = params;

  React.useEffect(() => {
    const historyParams =
      history.state && history.state.usr && history.state.usr.params;

    if (historyParams) {
      setPreviousPage(
        `/designs?${createSearchParams(historyParams).toString()}`,
      );
    }

    if (canvaTemplateId && !isNaN(parseInt(canvaTemplateId))) {
      setFetchStatus(ELoadingStates.LOADING);
      getDesignTemplate(parseInt(canvaTemplateId))
        .then((value) => {
          const proofFront = updateUrlSearchParam(value?.proofFront!);
          const proofBack = updateUrlSearchParam(value?.proofBack!);
          setCanvaTemplate(() => {
            return {
              ...value,
              proofFront,
              proofBack,
            } as GetTemplateByIdResponse;
          });
          setOriginalCanvaTemplate((prev) => {
            return {
              ...value,
              proofFront,
              proofBack,
            } as GetTemplateByIdResponse;
          });
          setSelectedProof(value?.startingSide || 'front');
          setFetchStatus(ELoadingStates.LOADED);
        })
        .catch((error) => {
          console.error(error);
          setFetchStatus(ELoadingStates.FAILED);
        });
    }
  }, [canvaTemplateId]);

  const blockerFunction: BlockerFunction = (event) => {
    if (event.currentLocation.pathname === event.nextLocation.pathname) {
      return false;
    }

    if (selectedShop && originalShopId !== selectedShop.id) {
      return false;
    }

    return canvaTemplateChanged;
  };

  const blocker = useBlocker(
    React.useCallback(blockerFunction, [
      canvaTemplateChanged,
      selectedShop?.id,
    ]),
  );

  React.useEffect(() => {
    if (selectedShop && originalShopId === null) {
      setOriginalShopId(selectedShop.id);
    } else if (selectedShop && originalShopId !== selectedShop.id) {
      navigate('/designs', {replace: true});
    }
  }, [selectedShop]);

  const defaultColorList: IColor[] = [
    {
      id: 307,
      name: 'White',
      displayName: 'white',
      hex: '#FFFFFF',
    },
  ];

  if (
    fetchStatus === ELoadingStates.IDLE ||
    fetchStatus === ELoadingStates.LOADING
  ) {
    return <Loading text="Loading design template..." variant="black" />;
  }

  if (!canvaTemplate) {
    return <NotFound />;
  }

  const colors: IColor[] =
    canvaTemplate.designData?.displayColors || defaultColorList;

  const updateSlotValues = (
    slotId: number | string,
    newData: Partial<IBuilderSlots>,
  ) => {
    if (isSaving) {
      return;
    }

    const newTemplate = {...canvaTemplate} as GetTemplateByIdResponse;
    const search = (slot: IBuilderSlots) => {
      if (+slot.slotId! === +slotId && slot.objects && slot.objects[0]) {
        const internalObject = slot.objects[0];
        slot.alias = newData.alias || slot.alias;
        slot.src = newData.src || slot.src;
        internalObject.src = newData.src || internalObject.src;
        internalObject.alias = newData.alias || internalObject.alias;
        internalObject.text = newData.text || internalObject.text;
        slot = {
          ...slot,
          type: slot.type,
          ...newData,
          objects: [{...internalObject}],
        } as IBuilderSlots;

        // @ts-ignore
        delete slot.designData;
      }
      return slot;
    };

    if (
      canvaTemplate?.designData &&
      canvaTemplate?.designData.front &&
      canvaTemplate?.designData.front.objects.length
    ) {
      const newSlotsFront = canvaTemplate?.designData.front.objects.map(
        search,
      ) as IBuilderSlots[];
      newTemplate.designData!.front.objects = [...newSlotsFront];
    }

    if (
      canvaTemplate?.designData &&
      canvaTemplate?.designData.back &&
      canvaTemplate?.designData.back.objects.length &&
      newTemplate.designData!.back
    ) {
      const newSlotsFront = canvaTemplate?.designData.back.objects.map(
        search,
      ) as IBuilderSlots[];
      newTemplate.designData!.back.objects = [...newSlotsFront];
    }
    const proofFront = updateUrlSearchParam(newTemplate?.proofFront!)!;
    const proofBack = updateUrlSearchParam(newTemplate?.proofBack!);

    setCanvaTemplate(() => {
      return {
        ...newTemplate,
        proofFront,
        proofBack,
      };
    });
    setOriginalCanvaTemplate((prev) => {
      return {
        ...prev,
        proofFront,
        proofBack,
      } as GetTemplateByIdResponse;
    });
  };

  const handleOnSave = () => {
    setIsSaving(true);
    let generateProofs = false;

    if (
      canvaTemplate.designData.color !== originalCanvaTemplate?.designData.color
    ) {
      generateProofs = true;
    }

    const updateTemplateOptions = {
      generateProofs,
    };

    updateTemplate(
      canvaTemplate.id,
      {
        name: canvaTemplate.name,
        startingSide: canvaTemplate.startingSide || 'front',
        customImagesFront: canvaTemplate.customImagesFront || false,
        customImagesBack: canvaTemplate.customImagesBack || false,
        customTextFront: canvaTemplate.customTextFront || false,
        customTextBack: canvaTemplate.customTextBack || false,
        customEmojiFront: canvaTemplate.customEmojiFront ?? true,
        customEmojiBack: canvaTemplate.customEmojiBack ?? true,
        autoApproval: canvaTemplate.autoApproval ?? false,
        approvalTime: canvaTemplate.autoApproval
          ? canvaTemplate.approvalTime
          : null,
      },
      updateTemplateOptions,
    )
      .then(() => {
        const proofFront = updateUrlSearchParam(canvaTemplate?.proofFront!)!;
        const proofBack = updateUrlSearchParam(canvaTemplate?.proofBack!);

        setOriginalCanvaTemplate((prev) => {
          return {
            ...prev,
            proofFront,
            proofBack,
          } as GetTemplateByIdResponse;
        });
        setCanvaTemplate(() => {
          return {
            ...canvaTemplate,
            proofFront,
            proofBack,
          };
        });
        setOriginalCanvaTemplate(() => {
          return {
            ...canvaTemplate,
            proofFront,
            proofBack,
          };
        });
        setCanvaTemplateChanged(false);
        setShowSavedMessage(true);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const onUpdatedWalkthrough = (walkthroughActions: WalkthroughStep[]) => {
    updateWalkthrough(canvaTemplate.id, walkthroughActions)
      .then(() => {
        setCanvaTemplate((prev) => {
          if (!prev) return prev;
          const {designData} = prev;
          designData.walkthroughActions = walkthroughActions;
          return {
            ...prev,
            designData,
          };
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onUpdateTemplate = (
    canvaTemplateUpdated: Partial<GetTemplateByIdResponse>,
  ) => {
    const newCanvaTemplate = {...canvaTemplate, ...canvaTemplateUpdated};
    setIsSaving(true);
    updateTemplate(canvaTemplate!.id, {
      name: newCanvaTemplate!.name,
      startingSide: newCanvaTemplate!.startingSide || 'front',
      customImagesFront: newCanvaTemplate!.customImagesFront || false,
      customImagesBack: newCanvaTemplate!.customImagesBack || false,
      customTextFront: newCanvaTemplate!.customTextFront || false,
      customTextBack: newCanvaTemplate!.customTextBack || false,
      customEmojiFront: newCanvaTemplate.customEmojiFront ?? true,
      customEmojiBack: newCanvaTemplate.customEmojiBack ?? true,
      autoApproval: newCanvaTemplate.autoApproval ?? false,
      approvalTime: newCanvaTemplate.autoApproval
        ? newCanvaTemplate.approvalTime
        : null,
    })
      .then(() => {
        setCanvaTemplate(() => ({...newCanvaTemplate}));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleOnPublish = async () => {
    try {
      setIsPublishing(true);
      const product = await updatePublishStatus({
        templateId: canvaTemplate.id,
        status: 'active',
      });

      navigate(`${previousPage}?tab=published`);
      setTimeout(() => {
        if (product) window.open(product.url, '_blank', 'noreferrer');
      }, 2000);
      setIsPublishing(false);
    } catch (error) {
      setIsPublishing(false);
      setShowErrorMessage(true);
    }
  };

  return (
    <div id="DesignControls" className="design-controls">
      <SlotControlsHeader
        canvaTemplate={canvaTemplate}
        isSaving={isSaving}
        handleOnSave={handleOnSave}
        handleOnRePublish={handleOnPublish}
        handleOnPublish={() => setShowShopifyPublishModal(true)}
        isPublishing={isPublishing}
        disableSaveButton={!canvaTemplateChanged}
        backTo={previousPage}
      />

      <SlotControlsMain
        isSaving={isSaving}
        canvaTemplate={canvaTemplate}
        onChangeCanvaTemplate={(canvaTemplate) => {
          if (isSaving) {
            return;
          }
          setCanvaTemplate(canvaTemplate);
          if (!isEqual(canvaTemplate, originalCanvaTemplate)) {
            setCanvaTemplateChanged(true);
          } else {
            setCanvaTemplateChanged(false);
          }
        }}
        selectedProof={selectedProof}
        onChangeSelectedProof={(selectedProof) =>
          setSelectedProof(selectedProof)
        }
        colors={colors}
        showMessage={showSavedMessage}
        showErrorMessage={showErrorMessage}
        onCloseMessage={() => setShowSavedMessage(false)}
      />

      <SlotControlsTabs
        canvaTemplate={canvaTemplate}
        templateId={canvaTemplateId!}
        updateSlotValues={updateSlotValues}
        onUpdateWalkthrough={onUpdatedWalkthrough}
        onUpdateTemplate={onUpdateTemplate}
        slotsOrder={canvaTemplate?.slotsOrder!}
        garmentColors={colors}
        isSaving={isSaving}
      />

      <ShopifyPublishModal
        show={showShopifyPublishModal}
        onCloseModal={() => setShowShopifyPublishModal(false)}
        design={canvaTemplate}
      />

      <CloseConfirmationModal
        show={blocker.state === 'blocked'}
        onClose={blocker.proceed}
        onContinue={blocker.reset}
      />
    </div>
  );
};

export default SlotTemplatePage;
