import React from 'react';
import {GetTemplateByIdResponse, IColor} from '@gfxco/contracts';
import {Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {XCircle} from 'react-bootstrap-icons';

import ColorItem from '../../components/ConfigureProductInfo/Items/ColorItem';
import ToastMessage from '../../components/ToastMessage';
import GFXButton from '../../components/Button';
import GFXToggle from '../../components/Toggle';
import GFXChips from '../../components/Chips';
import ProofImage from './ProofImage';
import AutoApproveOption from './AutoApproveOption';

import {ReactComponent as InformationIcon} from '../../assets/icons/information-icon.svg';
import {ReactComponent as FlipIcon} from '../../assets/icons/flip-icon.svg';
import {ReactComponent as EyeIcon} from '../../assets/icons/eye-icon.svg';
import {ReactComponent as CheckIcon} from '../../assets/icons/check-icon.svg';

import {capitalizeFirstLetter} from '../../libs/formatUtils';

import './SlotControlsMain.scss';

const {REACT_APP_EDITOR_URL, REACT_APP_LEGACY_API_URL} = process.env;

interface RequiredProps {
  canvaTemplate: GetTemplateByIdResponse;
  onChangeCanvaTemplate: (canvaTemplate: GetTemplateByIdResponse) => void;
  selectedProof: 'front' | 'back';
  onChangeSelectedProof: (selectedProof: 'front' | 'back') => void;
  colors: IColor[];
  isSaving: boolean;
}

interface OptionalProps {
  className?: string;
  showMessage?: boolean;
  showErrorMessage?: boolean;
  onCloseMessage?: () => void;
}

type Props = RequiredProps & OptionalProps;

const SlotControlsMain: React.FC<Props> = (props) => {
  const className = props.className || '';
  const [disableBackElements, setDisableBackElements] = React.useState(false);
  const defaultColor = props.canvaTemplate.designData.displayColors?.find(
    (c) => c.name === props.canvaTemplate.designData.color,
  );

  const PREVIEW_LINK = `${REACT_APP_EDITOR_URL}/?v1TemplateId=${
    props.canvaTemplate.id
  }&walkthrough=true${
    REACT_APP_LEGACY_API_URL ? `&apiUrl=${REACT_APP_LEGACY_API_URL}` : ''
  }`;

  const handleOnCloseMessage = () => {
    if (props.onCloseMessage) {
      props.onCloseMessage();
    }
  };

  const handleOnChangeTemplate = (
    canvaTemplateUpdate: Partial<GetTemplateByIdResponse>,
  ) => {
    if (props.isSaving) {
      return;
    }
    const newCanvaTemplate = {...props.canvaTemplate, ...canvaTemplateUpdate};
    props.onChangeCanvaTemplate(newCanvaTemplate);
  };

  const handleOnChangeSelectedProof = (side?: 'front' | 'back') => {
    if (side !== undefined) {
      props.onChangeSelectedProof(side);
      return;
    }

    if (props.selectedProof === 'front') {
      props.onChangeSelectedProof('back');
    } else {
      props.onChangeSelectedProof('front');
    }
  };

  React.useEffect(() => {
    const hasBack =
      props.canvaTemplate.designData.back !== null &&
      props.canvaTemplate.designData.back !== undefined;
    setDisableBackElements(hasBack);
  }, []);

  return (
    <div className={`design-controls__main ${className}`}>
      <div className="proof">
        <div className={`proof__imageContainer ${props.selectedProof}`}>
          <ProofImage
            proofUrl={props.canvaTemplate.proofFront}
            alt="proof-front"
          />
          <ProofImage
            proofUrl={props.canvaTemplate.proofBack!}
            alt="proof-back"
          />
        </div>

        {disableBackElements && (
          <div className="proof__controls">
            <GFXButton
              className="control"
              variant="light"
              onClick={() => handleOnChangeSelectedProof()}
            >
              <FlipIcon />
              <span>Flip view</span>
            </GFXButton>
          </div>
        )}
      </div>
      <div className="design-controls-form">
        <div
          className={`alert-container ${
            props.showErrorMessage ? 'error-container' : ''
          }`}
        >
          <ToastMessage
            onClose={() => handleOnCloseMessage()}
            show={(props.showErrorMessage || props.showMessage) ?? false}
            delay={3000}
          >
            <>
              {props.showMessage ? <CheckIcon /> : <XCircle />}
              {props.showMessage && (
                <span>
                  Your design has been <b>saved</b> successfully.
                </span>
              )}
              {props.showErrorMessage && (
                <span>
                  {`Something went wrong, your template couldn't be published.`}
                </span>
              )}
            </>
          </ToastMessage>
        </div>
        <div className="design-controls-form-grid">
          <div className="form-column form-column-left">
            <div className="section-controls-group">
              <Form.Group
                className="controls-form-group"
                controlId="designName"
              >
                <Form.Label>Design Name:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="My Design is Awesome"
                  value={props.canvaTemplate.name}
                  onChange={(e) => {
                    handleOnChangeTemplate({name: e.target.value});
                  }}
                />
              </Form.Group>
            </div>
            <div className="section-controls-group">
              <Form.Group controlId="printerReference">
                <Form.Label>Reference | Printer:</Form.Label>
                <Form.Control
                  plaintext
                  readOnly
                  value={props.canvaTemplate.printerReference}
                />
              </Form.Group>
            </div>
            {disableBackElements && (
              <div className="section-controls-group">
                <Form.Group controlId="startingSide">
                  <Form.Label>Select starting preview side:</Form.Label>
                  <div className="form__line stack">
                    <GFXButton
                      variant="light"
                      size="sm"
                      active={props.canvaTemplate.startingSide === 'front'}
                      onClick={() => {
                        handleOnChangeTemplate({startingSide: 'front'});
                        handleOnChangeSelectedProof('front');
                      }}
                    >
                      Front
                    </GFXButton>
                    <GFXButton
                      variant="light"
                      size="sm"
                      active={props.canvaTemplate.startingSide === 'back'}
                      onClick={() => {
                        handleOnChangeTemplate({startingSide: 'back'});
                        handleOnChangeSelectedProof('back');
                      }}
                    >
                      Back
                    </GFXButton>
                  </div>
                </Form.Group>
              </div>
            )}
            <div className="section-controls-group">
              <Form.Group controlId="defaultColor">
                <Form.Label>
                  This is your default color:{' '}
                  <b>
                    <i>
                      {capitalizeFirstLetter(defaultColor?.displayName || '')}
                    </i>
                  </b>{' '}
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="defaultColor">
                        To modify the default color, click on “Edit Template”.
                      </Tooltip>
                    }
                  >
                    <InformationIcon />
                  </OverlayTrigger>
                </Form.Label>
                <div className="form__line colors">
                  {props.colors.map((color) => (
                    <ColorItem
                      key={color.id}
                      color={color}
                      markBorder={color.hex === '#FFFFFF'}
                      onClick={() => null}
                      selected={
                        props.canvaTemplate.designData.color === color.name
                      }
                      showHex={false}
                    />
                  ))}
                </div>
              </Form.Group>
            </div>
          </div>
          <div className="form-column form-column-right">
            <div className="section-controls-group">
              <div className="preview-buttons form__line stack">
                <GFXButton
                  variant="secondary"
                  target="_blank"
                  href={PREVIEW_LINK}
                >
                  <EyeIcon />
                  Preview
                </GFXButton>
                <GFXButton variant="secondary" disabled>
                  Generate Preview Image
                </GFXButton>
              </div>
            </div>
            <div className="section-controls-group">
              <Form.Group
                className="custom-elements"
                controlId="customElements"
              >
                <Form.Label>Allow custom elements:</Form.Label>
                <Form.Group
                  className="form__line vertical-centered"
                  controlId="imagesCustomElements"
                >
                  <Form.Label>Images:</Form.Label>

                  <GFXToggle
                    id="allow-images-front"
                    label="Front"
                    checked={props.canvaTemplate.customImagesFront}
                    onChange={(e) => {
                      handleOnChangeTemplate({
                        customImagesFront: e.target.checked,
                      });
                    }}
                  />

                  {disableBackElements && (
                    <GFXToggle
                      id="allow-images-back"
                      label="Back"
                      checked={props.canvaTemplate.customImagesBack}
                      onChange={(e) => {
                        handleOnChangeTemplate({
                          customImagesBack: e.target.checked,
                        });
                      }}
                    />
                  )}
                </Form.Group>
                <Form.Group
                  className="form__line vertical-centered"
                  controlId="textCustomElements"
                >
                  <Form.Label>Text:</Form.Label>

                  <GFXToggle
                    id="allow-text-front"
                    checked={props.canvaTemplate.customTextFront}
                    label="Front"
                    onChange={(e) => {
                      handleOnChangeTemplate({
                        customTextFront: e.target.checked,
                      });
                    }}
                  />
                  {disableBackElements && (
                    <GFXToggle
                      id="allow-text-back"
                      checked={props.canvaTemplate.customTextBack}
                      label="Back"
                      onChange={(e) => {
                        handleOnChangeTemplate({
                          customTextBack: e.target.checked,
                        });
                      }}
                    />
                  )}
                </Form.Group>
                <Form.Group
                  className="form__line vertical-centered"
                  controlId="emojisCustomElements"
                >
                  <Form.Label>Emojis:</Form.Label>

                  <GFXToggle
                    id="allow-emojis-front"
                    label="Front"
                    checked={props.canvaTemplate.customEmojiFront}
                    onChange={(e) => {
                      handleOnChangeTemplate({
                        customEmojiFront: e.target.checked,
                      });
                    }}
                  />

                  {disableBackElements && (
                    <GFXToggle
                      id="allow-emojis-back"
                      label="Back"
                      checked={props.canvaTemplate.customEmojiBack}
                      onChange={(e) => {
                        handleOnChangeTemplate({
                          customEmojiBack: e.target.checked,
                        });
                      }}
                    />
                  )}
                </Form.Group>
              </Form.Group>
            </div>
            <div className="section-controls-group">
              <div>
                <AutoApproveOption
                  value={props.canvaTemplate.autoApproval || false}
                  onChange={(value) => {
                    handleOnChangeTemplate({
                      autoApproval: value.enabled,
                      approvalTime: value.approvalTime ?? null,
                    });
                  }}
                  approvalTime={props.canvaTemplate.approvalTime}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="section-controls-group slot-control-hide">
          <Form.Group controlId="tags">
            <Form.Label>
              Add design tags: <InformationIcon />
            </Form.Label>
            <GFXChips defaultValue={props.canvaTemplate.tags} disabled />
          </Form.Group>
        </div>
      </div>
    </div>
  );
};

export default SlotControlsMain;
